<template>
    <v-app-bar :color="`${mainColor}`" dark fixed app height="100"> 
        <v-card flat tile :color="`${secondaryColor}`" :class="{'secondary-toolbar': !is_home}"  class="hidden-sm-and-down" style="padding: 5px !important;">
              <v-flex xs9 sm9 offset-sm1 class="menu-body justify-center">
<!-- 
                vvvbehfbewhfhyewbh
                banner
                  <div>
                    <v-icon class="top-bar-icon mr-1">mdi-email-outline</v-icon>
                    <span class="contact-text">{{ companyEmail }}</span>
                  </div>
                  <v-divider  class="mx-4" vertical></v-divider>
                  <div>
                    <v-icon class="top-bar-icon mr-1">mdi-phone</v-icon>
                    <span class="contact-text">{{ companyPhoneNo }}</span>
                  </div> -->
                  <div>
                    <!-- <span class="contact-text"> Licensed and Regulated by the Central Bank of Kenya</span> -->
                  </div>
                  <div @click="redirect('press-release')" class="">
                    <span class="contact-text"> Licensed and Regulated by the Central Bank of Kenya 
                      <a class="blinking-text cursor-pointer ml-4 font-weight-bold contact-text">Read More</a>
                      </span>
                  </div>
                  <!-- <div>
                    <a @click="hide()">X</a>
                  </div> -->
<!--                  
                  <v-spacer></v-spacer>
                  <div  class="media-icon-holder">
                   <v-flex xs1 sm1 class="px-4 icon-box"  @click="redirect('press-release')" >
                     <span>Read More</span>
                   </v-flex>
                  </div> -->
              </v-flex>
        </v-card>
         <v-flex xs9 sm9 offset-sm2 class="menu-body hidden-sm-and-down">
            <v-avatar  height="40" width="141" tile class="mt-3 goHome" @click="goHome()" >
              <v-img :src="`${s3Path}logoWhite.png`" v-if="is_home"></v-img>
              <v-img :src="`${s3Path}logoPurple.png`" v-else></v-img>
            </v-avatar>
            <v-spacer></v-spacer>
            <v-toolbar-items class="hidden-sm-and-down menu-tool-bar">
              <template v-for="(item, index) in menu">
                  <v-menu offset-overflow  nudge-left="20" min-width="150" origin="center center"  offset-y  transition="slide-y-transition"   :key="index" v-if="item.isDropDown" class="" :class="(item.doubleSided) ? 'double-sided' : 'single-sided'" >
                    <template v-slot:activator="{ on, attrs }">
                      <a @click="changeRoute(item, null)"  dark  v-bind="attrs" v-on="on"  class="nav" :class="{'secondary-nav': !is_home, 'my-3': is_home, 'pa-3': is_home, 'pa-5': !is_home, 'router-link-exact-active': activeLink === item.link }" >
                        <span>{{  item.title }}</span>
                         <v-icon :color="is_home ? 'white' : zamuPurple" x-small class="ml-2" dark>mdi-play mdi-rotate-90</v-icon>
                      </a>
                    </template>
                     <v-row class="mt-5 mx-0">
                        <v-col cols="12"  lg="4" md="4" sm="12" xs="12" class="pa-0" :class="(item.doubleSided) ? 'col-sm-12 col-md-4 col-lg-4 col-12' : 'col-sm-12 col-md-12 col-lg-12 col-12'">
                            <v-card height="100%" dark :color="`${zamuPurple5}`"  tile flat>
                                <v-list class="pl-4 customer-link">
                                  <v-list-item  v-for="(link, r) in item.links"  :key="r"  class="menu-list-item mb-4">
                                    <v-list-item-title class="zamu-heading customer-link-header">{{ link.header }}</v-list-item-title>
                                    <v-list-item-subtitle  v-for="(linkContent, r) in link.content"  :key="r" @click="changeRoute(linkContent.link, linkContent.parent)" >
                                         <span class="zamu-heading customer-link-content"> {{ linkContent.name }}</span>
                                      </v-list-item-subtitle>
                                    <v-card class="mt-2" v-if="r === 0 && item.links .length > 1" flat tile width="50px" height="2px" :color="`${zamuYellow3}`"></v-card>

                                  </v-list-item>
                                </v-list>
                            </v-card>
                        </v-col>
                        <v-col cols="12" v-if="item.doubleSided" lg="8" md="8" sm="12" xs="12" class="pt-0 px-0 pb-0">
                          <v-card class="mt-3"  color="white" tile flat>
                            <v-row class="mx-0 py-4">
                              <v-col   cols="12"  lg="3" md="3" class="pa-0 text-right">
                                <v-avatar class="goHome" tile size="80" justify="right" @click="goHome()">
                                  <v-img :src="require('../../assets/images/rabbit.png')"></v-img>
                                </v-avatar>
                              </v-col>
                              <v-col  cols="12"  lg="9" md="9" class="pa-0 pl-2">
                                 <v-list class="menu-list">
                                  <v-list-item  v-for="(child, i) in item.children"  :key="i"  class="menu-list-item">
                                    <v-list-item-title class="zamu-heading why-us-heading">{{ child.header }}</v-list-item-title>
                                    <v-list-item-subtitle  v-for="(content, k) in child.content"  :key="k">
                                        <v-icon size="8" class="px-1 full-stop" :color="`${zamuYellow}`">mdi-checkbox-blank-circle</v-icon>
                                         <span class="why-us-list zamu-heading"> {{ content.name }}</span>
                                      </v-list-item-subtitle>
                                  </v-list-item>
                                </v-list>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-col>
                      
                     </v-row>
                  
                  </v-menu>
                  <a v-else :to="item.link" @click="changeRoute(item, null)"  :key="index" class="nav" :class="{'secondary-nav': !is_home, 'my-3': is_home, 'pa-3': is_home, 'pa-5': !is_home, 'router-link-exact-active': activeLink === item.link }" >
                    <span>{{  item.title }}</span>
                  </a> 
              
              </template>

              <template v-for="item in authenticatedMenu">
                <a v-if="isAuthenticated" :to="item.link" @click="changeRoute(item, null)"  :key="item.title" class="nav " :class="{'secondary-nav': !is_home, 'my-3': is_home, 'router-link-exact-active': activeLink === item.link, 'pa-3': is_home, 'pa-5': !is_home,}" >
                  <span >{{  item.title }}</span>
                </a> 
              </template>
              <v-btn height="26"  :color="`${zamuYellow3}`" x-small class="sign-in ml-6" v-if="isAuthenticated" @click="logout()" :class="{'mt-6': is_home, 'mt-4': !is_home}">
                Sign out
                <v-icon small>mdi-chevron-right</v-icon>
              </v-btn>
              
            </v-toolbar-items>
         </v-flex>
         <v-flex xs12 sm6 offset-sm2  class="menu-body hidden-md-and-up">
           <v-menu :close-on-content-click="showMobileMenu" open-on-hover bottom offset-y>
              <template v-slot:activator="{ on }">
                 <v-app-bar-nav-icon :color="`${iconColor}`" v-on="on" @click="toggleMenu()"></v-app-bar-nav-icon>
                 <v-avatar  height="40" width="141" tile class="mt-3 goHome" @click="goHome()" >
                    <v-img :src="`${s3Path}logoWhite.png`" v-if="is_home"></v-img>
                    <v-img :src="`${s3Path}logoPurple.png`" v-else></v-img>
                  </v-avatar>

              </template>
             
                <v-list class="mobile-view-list overflow-y-auto">
                <v-list-group no-action v-for='(navLink, r) in menu' :key="r" :class="{'not-dropdown': !navLink.isDropDown}">
                    <v-list-item slot='activator'  >
                        <v-list-item-title v-if="!navLink.isDropDown" @click="changeRoute(navLink.link, null)">{{ navLink.title }}</v-list-item-title>
                        <v-list-item-title v-else>{{ navLink.title }}</v-list-item-title>

                    </v-list-item>
                    <template v-if="navLink.isDropDown">
                       <v-list-item v-for='(sub, c) in navLink.links' :key="`link_${c}`" class="dropdown-mobile-list pl-7">
                         <template v-for='(content, c) in sub.content'>
                          <v-list-item-title class="dropdown-text" :key="`sub_${c}`" @click="changeRoute(content.link, content.parent)">{{ content.name }} <br /></v-list-item-title>
                         </template>
                       </v-list-item>

                    </template>
                </v-list-group>
            </v-list>
               
              </v-menu>
          </v-flex>
</v-app-bar>
</template>

<script>
import colorMixin from '@/mixins/colorMixin';
import coreMixin from '@/mixins/coreMixin';
import {  mapGetters, mapMutations } from 'vuex';


export default {
    name: 'AppBar',
     components: {
     },
    mixins: [colorMixin, coreMixin],
    data () {
      return {
            dialog: false,
            activeLink: '',
            showMobileMenu: false,
           menu: [
            { 
              icon: "home", 
              title: "Why ZamuPay", 
              link: "why-us", 
              authenticated: false, 
              isDropDown: true, 
              doubleSided: true,
              children: [
                { 
                  header: 'why choose us',
                  content: [
                    { name: 'Flexibility of Payments' },
                    { name: 'One-stop Shop Convenience' },
                    { name: 'Phenomenal Transaction Success Rates' },
                    { name: 'Integration of Payment Experience' },
                    { name: 'Advanced Fraud Detection' },
                    { name: 'Dynamic' },
                    { name: 'Virtual Terminal' },

                  ],
                }
              ],
              links: [
                { 
                  header: 'ZamuPay For',
                  content: [
                    { name: 'International Companies', link: 'international',  parent: 'why-us' },
                    { name: 'Corporates', link: 'corporate',  parent: 'why-us' },
                    { name: 'SMEs', link: 'SMEs',  parent: 'why-us' },
                    { name: 'Saccos', link: 'saccos',  parent: 'why-us' },
                  ],
                },
                 { 
                  header: 'ZamuPay For',
                  content: [
                    { name: 'Fintechs', link: 'fintech',  parent: 'why-us' },
                    { name: 'Financial Institutions', link: 'financial',  parent: 'why-us' },
                    { name: 'Cross Border Transfers', link: 'cross-border', parent: 'why-us' },
                  ],
                }
              ]
              },
            { icon: "home", title: "Features", link: "features", authenticated: false, isDropDown: false },
            { icon: "home", title: "Messaging", link: "messaging", authenticated: false, isDropDown: false },
            // { icon: "developers", title: "Developers", link: "developers", authenticated: false, isDropDown: false },
            { icon: "about", title: "About us", link: "about-us", authenticated: false, isDropDown: false  },
           // { icon: "blog", title: "Blog", link: "blog", authenticated: false, isDropDown: false, doubleSided: false  },

             { 
              icon: "home", 
              title: "Resources", 
              link: "usecases", 
              authenticated: false, 
              isDropDown: true, 
              doubleSided: false,
              links: [
                { 
                  content: [
                    { name: 'Blog', link: 'blog',  parent: 'resources' },
                    { name: 'Careers', link: 'careers',  parent: 'resources' },
                    { name: 'Developers', link: 'developers',  parent: 'resources' },

                  ],
                }
              ]
              },

            { 
              icon: "legal", 
              title: "Legal", 
              link: "legal", 
              authenticated: false, 
              isDropDown: true, 
              doubleSided: false,
              links: [
                { 
                  content: [
                    { name: 'Terms & Conditions', link: 'termsConditions',  parent: 'legal' },
                    { name: 'Privacy Policy', link: 'privacyPolicy',  parent: 'legal' },

                  ],
                }
              ]
              },
            { 
              icon: "support", 
              title: "Support", 
              link: "support", 
              authenticated: false, 
              isDropDown: true, 
              doubleSided: false,
              links: [
                { 
                  content: [
                    { name: 'FAQs', link: 'faqs',  parent: 'support' },
                    { name: 'Contact Us', link: 'support',  parent: 'support' },

                  ],
                }
              ]
              },

            
          ],
          authenticatedMenu : [ 
            { icon: "support", title: "Admin", link: "admin", authenticated: true},
          ]
      };
    },
    computed: {
      ...mapGetters(['isAuthenticated', 'getActiveLink']),

      mainColor() {
        return this.is_home ? this.zamuPurple2 : '#ffffff';
      },
      iconColor() {
        return !this.is_home ? this.zamuPurple2 : '#ffffff';
      },
      secondaryColor () {
        return this.zamuYellow2;
        // return 'red'
      },
    
    },
    watch: {
      getActiveLink(link) {
         this.activeLink = link;
      },
   },
    mounted () {
       this.activeLink = this.getActiveLink;

    },
    methods: {
    ...mapMutations(['setAccessToken', 'setActiveLink']),

      menuItems() {
        return this.menu;
      },
      hide(){

      },
  
      logout() {
        this.setAccessToken(null);
        this.setSessionData(null);
        localStorage.clear();
        this.$router.push('/admin');
        location.reload();
      },
      toggleMenu() {
         this.showMobileMenu = false;

      },
      changeRoute(item, parent) {
        console.log('Here is the link', item, parent);
        if (typeof item === 'string') {

          if(item === 'developers') {
              window.open("https://documenter.getpostman.com/view/16549090/Tzm8GbJ7", '_blank');

            } else {
              this.activeLink = parent;
              this.$router.push({ name: item }).catch(()=>{});
              
            }
          

         

        } else {
            if(item.link === 'developers') {
              window.open("https://documenter.getpostman.com/view/16549090/Tzm8GbJ7", '_blank');

            } else {
               this.activeLink = item.link;
              // eslint-disable-next-line no-unused-vars
               const res =  !item.isDropDown ? this.$router.push({ name: item.link }).catch(()=>{}) : '';
              
            }
        }
        localStorage.setItem('activeLink', this.activeLink);
        this.setActiveLink( this.activeLink);
        this.showMobileMenu = true;




      },
      redirect(url) {
        return window.location=url;
      }
  }


}
</script>

<style>
.v-list-item__content {
  cursor: pointer;
  color: #ffffff !important
}
.menu-list-item {
  display: block;
  padding: 0;

}
.menu-list {
  background: #ffffff !important;
  padding: 0;
}
.customer-link {
      background: #401b73 !important;
}
.why-us-heading {
  font-size: 20px;
    color: #43237d;
    letter-spacing: normal !important;
    line-height: 38px;
}
.full-stop.v-icon {
  line-height: 16px !important;
  letter-spacing: 4px !important;

}
.why-us-list {
    line-height: 38px;
    font-size: 14px;
    color: #43237d;
}
.customer-link-header {
  color: #d29f2a;
}
.customer-link-content {
    line-height: 34px;
    font-size: 14px;
    color: #ffffff;
    cursor: pointer;
}
.double-sided {
    width: 600px;
    min-width: 600px;
    max-width: 700px;
    overflow: hidden;
}
.menuable__content__active {
    overflow: hidden;
}
.not-dropdown .v-list-group__header__append-icon {
  display: none;
}
.dropdown-mobile-list{
  display: block;
}
.dropdown-text {
    font-size: 14px;
    padding: 12px;
    cursor: pointer;
}
.mobile-view-list {
  max-height: 90vh;
}
.customer-link-content:hover, customer-link-content:focus {
  color: #d29f2a;
}
.router-link-exact-active .v-icon {
    color: #d09e2a !important ;

}
@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}
.blinking-text {
  animation: blink 1s infinite;
  cursor: pointer;
}
.contact-text {
  font-size: 13px !important;
}
</style>