<template>
    <div class="FAQHolder">
        <Slider />
        <v-flex xs10 sm10 offset-sm1 class="">
            <p><br></p>
            <p class="terms-header">Zamupay Receives Official License as a Payments Service Provider (PSP) from the Central Bank of Kenya</p>
        <p>
            Zamupay is delighted to announce that we have been officially licensed as a Payments Service Provider (PSP) by the Central Bank of Kenya (CBK). This achievement highlights our unwavering commitment to offering secure, innovative, and seamless payment services for businesses and individuals.
        </p>
        <p>
            It marks a major milestone that recognizes our dedication to the highest standards of regulatory compliance and governance. We are deeply grateful to the Central Bank of Kenya for granting us this license, which strengthens our capacity to serve Kenya’s dynamic financial landscape.
        </p>
        <p>
            Reflecting on this accomplishment, our Managing Director, Benson Muteru, noted:
        </p>
        <p style="margin-left: 2em;">
            “This license marks a new chapter for Zamupay and, <a href="http://www.pycs.co.ke" target="_blank">PYCS</a>, enabling us to deliver even more secure and innovative payment solutions to our customers. It reinforces our commitment to driving financial inclusion and creating seamless experiences for individuals and businesses. For Zamupay, this is a significant step in our journey to lead the transformation of the payments landscape in Kenya and beyond.”
        </p>

        <p class="terms-header">Empowering Growth and Innovation</p>
        <p>
            With the PSP license, Zamupay is positioned for accelerated growth, extending our reach and enabling businesses to benefit from innovative payment solutions. From day-to-day transactions to secure money transfers, our services are designed to enhance the financial journey of every customer.
        </p>
        <p>
            As we embark on this new chapter, we remain focused on delivering outstanding support and a seamless customer experience. Our goal is to contribute meaningfully to Kenya’s digital economy while ensuring financial inclusion for all.
        </p>
        <p class="terms-header">About Zamupay</p>

        <p>
            Zamupay is a fully owned subsidiary of <a href="http://www.pycs.co.ke" target="_blank">PYCS</a>, a company known for pioneering impactful financial solutions. As a leading provider of secure, customer-centric payment services, Zamupay offers digital payment solutions and money transfers tailored to meet diverse business and individual needs. Guided by our core values of trust, innovation, and customer satisfaction, we continue to redefine the payments landscape in Kenya and beyond.

        </p>
        <p>
            Zamupay offers a wide range of services including:

        </p>
        <p>
        <ul>
           
            <li><b>Bulk & Petty Cash Payments:</b> Disbursing large or small-scale payments efficiently.</li>
            <li><b>API Integrations: </b>Customized APIs for FinTechs, ERPs, financial institutions, payments, startups, and international companies.</li>
            <li><b>Collections: </b>Simplified and secure payment gateway for receiving payments from clients and customers.</li>
            <li><b>Bill Payments: </b>Convenient options for paying utility bills and other obligations.</li>
            <li><b>Airtime Purchases: </b>Seamless and fast airtime top-ups for multiple users.</li>
            <li><b>Statutory Payments:</b> Hassle-free payments for KRA, NSSF, NHIF, and other statutory requirements.</li>
            <li><b>KYC Verification:</b> Payment Gateway that verifies KYC information such as M-Pesa, national IDs, NTSA, BRS, and KRA.</li>
        </ul>
        </p>

        <p>
            We look forward to working closely with our partners, customers, and stakeholders to further enhance the country’s payment ecosystem and thank everyone for their ongoing trust and support.
        </p>
        <p><br></p>


        </v-flex>
    </div>
  </template>
  
  <script>
  export default {
      name: 'PressRelease',
      components: {
        Slider: () => import('./slider'),
  
      },
  
  }
  </script>
  <style scoped>
  .terms-header {
    font-weight: 700;
  }
  .FAQHolder p, .FAQHolder li {
      font-size: 14px;
      line-height: 30px;
  }
  
  </style>