<template>
        <v-card color="" height="" class="faqs-holdert" tile flat >
            <v-card-title class="">
                <span class="zamu-heading address-holder mb-4">ADDRESS</span>
                <span class="zamu-sub-heading address-holder company-name">{{ companyName }}</span>
                <span class="address-holder company-details">{{ companyBuilding }}</span>
                <span class="address-holder company-details">{{ companyAddress }}</span>
                <span class="address-holder company-details">Telephone: {{ companyPhoneNo }}</span>
                <!-- <span class="address-holder company-details">FAX: {{ companyFax }}</span> -->
                <span class="address-holder company-details mt-4">E-mail: <span class="address-text ml-1">{{ salesEmail }}</span></span>
                <!-- <span class="address-holder company-details ">&nbsp;<span class="address-text ml-12">{{ salesEmail }}</span></span> -->
                <span class="address-holder company-details ">&nbsp;<span class="address-text ml-12">{{ supportEmail }}</span></span>

                <span class="address-holder company-details">Website: <span class="address-text ml-1">{{ website }}</span></span>

            </v-card-title>
        </v-card>
  
</template>

<script>
import colorMixin from '@/mixins/colorMixin';
import coreMixin from '@/mixins/coreMixin';

export default {
    name: 'Address',
    mixins: [colorMixin, coreMixin],


}
</script>

<style>

</style>